import React, { useState } from 'react';

const Faqs = ({ title, faqs }) => {
  const [showAccordion, setShowAccordion] = useState([]);
  const accordion = [];
  const height = [];

  const toggleAccordion = (idx, e) => {
    const parent = e.currentTarget.parentNode;
    let accordionBody = parent.getElementsByClassName('accordion-body-inner')[0].offsetHeight;
    let toggle = true;
    if (typeof showAccordion.accordion !== 'undefined') {
      toggle = !showAccordion.accordion[idx];
    }

    accordion[idx] = toggle;
    height[idx] = accordionBody;

    setShowAccordion({
      accordion,
      height
    });
  };

  const addAccordionClass = (idx) => {
    if (showAccordion.accordion) {
      return showAccordion.accordion[idx]
    }

    return false;
  };

  const getInnerHeight = (idx) => {
    if (showAccordion.height) {
      return showAccordion.height[idx]
    }

    return 0;
  };

  return (
    <div className="services-faq-wrapper">
      <div className="container">
        <h2>{title}</h2>
        {faqs.map((faq, idx) => (
          <div className={`accordion ${addAccordionClass(idx) ? 'open' : 'closed'}`} key={`${faq.question}-${idx}`}>
            <div className="accordion-title"
              onClick={(e) => {
                e.preventDefault();
                toggleAccordion(idx, e);
              }}>
              {faq.question}
              <div
                className="cross"
              />
            </div>
            <div className="accordion-body" style={{ height: getInnerHeight(idx) }}>
              <div
                className="accordion-body-inner"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: faq.answer,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
