import React from 'react';
import Carousel from 'react-multi-carousel';
import { calculateRating } from '../helper';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/styles/components/customer-reviews.scss';

const ReviewsCarousel = ({ reviews }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const showPagination = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 1024) {
        return reviews.length > 3;
      } else {
        return reviews.length > 1;
      }
    }

    return true;
  }

  if (reviews.length === 0) {
    return null;
  }

  return (
    <div className="customer-reviews-wrapper">
      <div className="container">
        <div className="intro">
          <h2>
            Latest Customer Reviews
          </h2>
        </div>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          infinite={false}
          autoPlay={true}
          autoPlaySpeed={30000}
          centerMode={false}
          className=""
          dotListClass=""
          draggable={true}
          focusOnSelect={false}
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          showDots={showPagination()}
          swipeable
        >
          {reviews.length > 0 && (
            reviews.map((node) => (
              <div className="customer-review-slide" key={node.reviewText}>
                <div className="customer-review-slide-inner">
                  <div className="review">
                    {node.reviewText}
                  </div>
                  <div className="rating-stars-container">
                    <span className="rating-stars rating-stars-off">★★★★★</span>
                    <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(node.rating)}%` }}>★★★★★</span>
                  </div>
                  <div className="review-author">
                    {node.customerName} - {node.reviewDate}
                  </div>
                  <div className="review-car">
                    {node.pbName}
                  </div>
                </div>
              </div>
            ))
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
