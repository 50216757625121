import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';
import CustomerReviews from '../components/services/reviews';
import HeroBanner from '../components/services/hero-banner';
import Breadcrumb from '../components/services/breadcrumb';
import Intro from '../components/services/intro';
import MoreInfo from '../components/services/more-info';
import { calculateRating } from '../components/helper';
import SearchForm from '../components/services/search-form';
import Faqs from '../components/services/faqs';
import CallToAction from '../components/services/call-to-action';
import '../assets/styles/templates/sevices-list.scss';

const Suburbs = ({ data, pageContext }) => {
  let pageData = data.seolocationstate;
  let searchPlaceholder = '';

  if (pageData === null && data.seoservicestate !== null) {
    data.seoservicestate.statesData.forEach(val => {
      if (val.url === pageContext.url) {
        pageData = val;
        searchPlaceholder = data.seoservicestate.searchPlaceholder;
      }
    });
  }
  if (pageData === null && data.seocarstate !== null) {
    data.seocarstate.statesData.forEach(val => {
      if (val.url === pageContext.url) {
        pageData = val;
        searchPlaceholder = data.seocarstate.searchPlaceholder;
      }
    });
  }

  if (searchPlaceholder === '' && data.seolocationstate !== null) {
    searchPlaceholder = data.seolocationstate.searchPlaceholder;
  }

  const { seo, breadcrumbs, suburbs, reviews, faqs, jumbotron, moreInfo, quoteInfo, searchText, aboutInfo, structuredData } = pageData;

  return (
    <Layout wrapperClass="standard-page service-list-wrapper" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData={structuredData}
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <HeroBanner subHeading={jumbotron.header} title={jumbotron.body} btnText={jumbotron.button} />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <SearchForm searchTitle={searchText} placeholderCopy={searchPlaceholder} />
      <div className="container">
        <div className={`row ${pageData.type} section`}>
          {suburbs.map((node) => (
            <a href={node.url} key={node.url} className="col-3 suburb-tile text-center">
              <div className="card">
                <div className="card-body">
                  <h3>{node.name}</h3>
                  {parseInt(node.reviewOverview.count) > 5 && (
                    <>
                      <p className="text-muted">{node.totalJobs} Jobs</p>
                      <div className="rating-stars-wrapper">
                        <div className="rating-stars-container">
                          <span className="rating-stars rating-stars-off">★★★★★</span>
                          <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(node.reviewOverview.reviewAverage)}%` }}>★★★★★</span>
                        </div>
                      </div>
                      <div className="rating text-muted small mb-1">{node.reviewOverview.count} Reviews</div>
                    </>
                  )}
                </div>
              </div>
            </a>
          ))}
        </div>
        {aboutInfo && (
          <Intro title={aboutInfo.header} text={aboutInfo.body} classWrapper="outro" />
        )}
        {moreInfo && (
          <MoreInfo moreCTA={moreInfo.title} moreText={moreInfo.body} moreTitle={moreInfo.header} />
        )}
      </div>
      {quoteInfo && (
        <CallToAction callToAction={quoteInfo} />
      )}
      {faqs && (
        <Faqs title={faqs.title} faqs={faqs.faqs} />
      )}
      {reviews && (
        <CustomerReviews reviews={reviews} />
      )}
    </Layout>
  );
};

export const query = graphql`
  query SuburbQuery($url: String!) {
    seolocationstate(url: { eq: $url }) {
      breadcrumbs {
        text
        url
      }
      searchPlaceholder
      jumbotron {
        body
        button
        header
      }
      moreInfo {
        body
        header
        title
      }
      reviews {
        customerName
        pbName
        rating
        reviewDate
        reviewText
      }
      searchText
      structuredData
      seo {
        canonical
        description
        heading
        og {
          description
          image
          title
          type
          url
        }
        title
      }
      short
      state
      suburbs {
        name
        postcode
        reviewOverview {
          count
          reviewAverage 
        }
        totalJobs
        url
      }
      title
      url
    }
    seoservicestate(statesData: {elemMatch: {url: {eq: $url}}}) {
      searchPlaceholder
      statesData {
        url
        aboutInfo {
          body
          header
        }
        breadcrumbs {
          text
          url
        }
        exploreInfo {
          body
          header
        }
        faq {
          faqs {
            answer
            question
          }
          header
        }
        jumbotron {
          body
          header
        }
        reviews {
          customerName
          pbName
          rating
          reviewDate
          reviewText
        }
        searchText
        structuredData
        seo {
          canonical
          description
          heading
          og {
            description
            image
            title
            type
            url
          }
          title
        }
        service
        short
        state
        suburbs {
          name
          postcode
          reviewOverview {
            count
            reviewAverage
          }
          totalJobs
          url
        }
        title
      }
      quoteInfo {
        header
        button
      }
    }
    seocarstate(statesData: {elemMatch: {url: {eq: $url}}}) {
      searchPlaceholder
      statesData {
        url
        aboutInfo {
          body
          header
        }
        breadcrumbs {
          text
          url
        }
        exploreInfo {
          body
          header
        }
        faq {
          faqs {
            answer
            question
          }
          header
        }
        jumbotron {
          body
          header
        }
        reviews {
          customerName
          pbName
          rating
          reviewDate
          reviewText
        }
        searchText
        structuredData
        seo {
          canonical
          description
          heading
          og {
            description
            image
            title
            type
            url
          }
          title
        }
        service
        short
        state
        suburbs {
          name
          postcode
          reviewOverview {
            count
            reviewAverage
          }
          totalJobs
          url
        }
        title
      }
    }
  }
`;

Suburbs.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Suburbs;
