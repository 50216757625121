import React from 'react';

const Intro = ({ title, text, classWrapper }) => (
  <div className={`text-center intro-wrapper ${classWrapper}`}>
    {title && classWrapper === 'intro' && (
      <h3>{title}</h3>
    )}
    {title && classWrapper === 'outro' && (
      <h2>{title}</h2>
    )}
    {text && (
      <div
        className="intro-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )}
  </div>
);

export default Intro;
