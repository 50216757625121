import React from 'react';

const CallToAction = ({ callToAction }) => {
  const btnCopy = (callToAction.button) ? callToAction.button : 'Compare Quotes';
  
  return (
    <div className="call-to-action-wrapper">
      <div className="container">
        {callToAction.header && (
          <h3 className="mb-4">{callToAction.header}</h3>
        )}
        {btnCopy && (
          <a href="/quote" className="btn">{btnCopy}</a>
        )}
      </div>
    </div>
  );
};

export default CallToAction;
