import React from 'react';
import { Link } from 'gatsby';

const HeroBanner = ({ subHeading, title, btnText }) => {
  const btnCopy = (btnText) ? btnText : 'Compare Quotes';

  return (
    <div className="hero-banner">
      <div className="container">
        {subHeading && (
          <h1>{subHeading}</h1>
        )}
        {title && (
          <h4>
            {title}
          </h4>
        )}
        <Link to="/quote" className="btn">{btnCopy}</Link>
      </div>
    </div>
  );
};

export default HeroBanner;
