import React from 'react';

const Intro = ({ breadcrumbs }) => {
  const renderCrumb = (crumb) => {
    if (crumb.url === '') {
      return (
        <li className="breadcrumb__item" key={crumb.text}>
          <span className="breadcrumb__link breadcrumb__link__active">
            {crumb.text}
          </span>
        </li>
      )
    }

    return (
      <li className="breadcrumb__item" key={crumb.text}>
        <a className="breadcrumb__link" href={crumb.url}>{crumb.text}</a>
        <span className="breadcrumb__separator" aria-hidden="true"> / </span>
      </li>
    )
  };

  return (
    <nav className="breadcrumb" aria-label="Breadcrumb">
      <ol className="breadcrumb__list">
        {breadcrumbs.map((crumb) => {
          return renderCrumb(crumb);
        })}
      </ol>
    </nav>
  );
};

export default Intro;
