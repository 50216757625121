import React, { useState } from 'react';

const MoreInfo = ({ moreCTA, moreText, moreTitle }) => {
  const [showAccordion, setShowAccordion] = useState([]);
  const accordion = [];
  const height = [];

  const toggleAccordion = (idx, e) => {
    const parent = e.currentTarget.parentNode;
    let accordionBody = parent.getElementsByClassName('accordion-body-inner')[0].offsetHeight;
    let toggle = true;
    if (typeof showAccordion.accordion !== 'undefined') {
      toggle = !showAccordion.accordion[idx];
    }

    accordion[idx] = toggle;
    height[idx] = accordionBody;

    setShowAccordion({
      accordion,
      height
    });
  };

  const addAccordionClass = (idx) => {
    if (showAccordion.accordion) {
      return showAccordion.accordion[idx]
    }

    return false;
  };

  const getInnerHeight = (idx) => {
    if (showAccordion.height) {
      return showAccordion.height[idx]
    }

    return 0;
  };

  return (
    <div className={`accordion ${addAccordionClass(0) ? 'open' : 'closed'}`}>
      <div className="accordion-title"
        onClick={(e) => {
          e.preventDefault();
          toggleAccordion(0, e);
        }}>
        {moreCTA}
        <div
          className="cross"
        />
      </div>
      <div className="accordion-body" style={{ height: getInnerHeight(0) }}>
        <div className="accordion-body-inner">
          <div className="accordion-body-inner">
            {moreTitle && (
              <h3>{moreTitle}</h3>
            )}
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: moreText,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
